export default {
  customerType: {
    formFields: {
      customerType: {
        options: {
          tenant: {
            label: 'Mieter',
          },
          owner: {
            label: 'Eigentümer',
          },
          propertyManager: {
            label: 'Hausverwalter',
          },
        },
      },
    },
    message1:
      'Guten Tag! Ich würde Sie gern kennenlernen, um für Sie passende Infos oder Produkte zu finden. Ganz unverbindlich mit nur 3 Fragen.',
    message2: '<b>In welcher Eigenschaft wenden Sie sich an uns?</b>',
    title: 'Eigenschaft',
    selectionLabel: 'Sie sind'
  },
  container: {
    formFields: {
      container: {
        options: {
          sundae: {
            label: 'Becher',
          },
          waffle: {
            label: 'Waffel',
          },
        },
      },
    },
    label: 'Welchen Behälter möchtest du?',
    title: 'Behälter',
  },
  loadingProducts: 'Produkte werden geladen...',
  products: {
    message1: 'Vielen Dank für Ihre Antworten! Wir haben folgende Ergebnisse für Sie gefunden:',
    noProductsFound: 'Es wurden keine Produkte für Ihre Auswahl gefunden.',
    title: 'Produkte',
  },
  start: {
    label:
      'Darf Ihnen unser Assistent helfen, individuelle Produkte für Sie zu finden?',
    title: 'Start',
  },
  startAssistant: 'Assistenten starten',
  stopAssistant: 'Assistenten beenden',
  service: {
    formFields: {
      service: {
        label: 'Service',
        options: {
          information: {
            label: 'Informationen zu einem Thema',
          },
          solution: {
            label: 'Lösung für ein Problem',
          },
          modernization: {
            label: 'Modernisierung, Erweiterung oder Neubau',
          },
        },
      },
    },
    message1: 'Alles klar! Falls Sie eine Angabe ändern möchten, wählen Sie diese einfach oben aus.',
    message2: '<b>Womit können wir Ihnen weiterhelfen?</b>',
    title: 'Service',
    selectionLabel: 'Sie suchen'
  },
  topic: {
    formFields: {
      topic: {
        label: 'Thema',
        options: {
          waterQualityCheck: {
            label: 'Überprüfung der Trinkwasserqualität',
          },
          electroMobility: {
            label: 'Elektromobilität',
          },
          sustainability: {
            label: 'Nachhaltigkeit',
          },
          heating: {
            label: 'Heizung und Wärme',
          },
          convinceLandlordToModernize: {
            label: 'Vermieter von Modernisierung überzeugen',
          },
          consultationPlanningAndFunding: {
            label: 'Beratung, Planung und Fördermittel',
          },
          energyCertificateAndLegalTopics: {
            label: 'Energieausweis & andere rechtliche Themen',
          },
          buildingChecksAndAnalytics: {
            label: 'Checks und Analysen für mein Gebäude',
          },
          findCraftsman: {
            label: 'Passenden Handwerker finden',
          },
          legalTopics: {
            label: 'Rechtliche Themen',
          },
          grantsAndFunding: {
            label: 'Fördermittel und Finanzierung',
          },
        },
      },
    },
    message1: {
      fallback: 'Okay.',
      information: 'Super! Für unabhängige Informationen sind sie bei uns genau richtig.',
      solution: 'Okay. Für die meisten Probleme finden wir eine gute Lösung!',
      modernization: 'Wir bieten unterschiedlichste Leistungen rund um Beratung, Planung und Umsetzung!'
    },
    message2: '<b>Um welches Thema geht es denn?</b>',
    title: 'Thema',
    selectionLabel: 'Thema'
  },
  yourAnswer: {
    label: 'IHRE ANTWORT:',
  },
  skipAnswer: {
    label: "Nichts Davon / Frage Überspringen"
  },
  results: {
    label: 'ERGEBNISSE'
  },
};
