import { StepId } from '../consts/assistant-steps';

const stepValueToFilterMap = {
  [StepId.CUSTOMER_TYPE]: {
    tenant: 'mieter',
    owner: 'eigentümer',
    propertyManager: 'hausverwalter',
  },
  [StepId.SERVICE]: {
    information: 'information',
    modernization: 'vorhaben',
    solution: 'problem',
  },
  [StepId.TOPIC]: {
    consultationPlanningAndFunding: ['beratung', 'energieberatung'],
    energyCertificateAndLegalTopics: ['energieausweis', 'rechtliches'],
    buildingChecksAndAnalytics: ['checks', 'checks und analysen'],
    waterQualityCheck: 'trinkwasser',
    electroMobility: 'elektromobilität',
    sustainability: 'nachhaltigkeit',
    heating: ['wärme', 'heizung'],
    findCraftsman: ['handwerker', 'vermittlung von handwerkern'],
    legalTopics: 'rechtliches',
    convinceLandlordToModernize: 'vermieter-überzeugen',
    grantsAndFunding: ['finanzierung', 'fördermittel'],
  },
};

export default stepValueToFilterMap;
