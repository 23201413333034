







import { defineComponent, onMounted, ref } from '@vue/composition-api';

export default defineComponent({
  props: {
    productTemplate: {
      default: '',
      type: String,
    },
  },
  setup(props) {
    const container = ref<HTMLElement | null>(null);

    onMounted(async () => {
      if (container.value === null) {
        return;
      }

      /**
       * If no shadow dom is supported, simply set inner html
       */
      if (!HTMLElement.prototype.attachShadow) {
        container.value.innerHTML = props.productTemplate;
        return;
      }

      if (!container.value.shadowRoot) {
        container.value.attachShadow({ mode: 'open' });
      }

      if (container.value.shadowRoot === null) {
        return;
      }

      container.value.shadowRoot.innerHTML = props.productTemplate;
    });

    return { container };
  },
});
