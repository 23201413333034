import { StepId } from '../../consts/assistant-steps';
import { FormFieldType } from '../../consts/input-types';
import {
  FormMultipleChoiceConfig,
  StepDefinition,
} from '../../interfaces/step-definition';
import component from './CustomerType.vue';

const config: StepDefinition = {
  component,
  fields: [
    {
      config: {
        columns: 3,
        options: [
          { icon: 'key', value: 'tenant' },
          { icon: 'home', value: 'owner' },
          {
            icon: 'building',
            value: 'propertyManager',
          },
        ],
        singleAnswer: true,
        verticalOptionLayout: true,
      } as FormMultipleChoiceConfig,
      name: 'customerType',
      type: FormFieldType.MultipleChoice,
    },
  ],
  id: StepId.CUSTOMER_TYPE,
  skippable: true,
};

export default config;
