

























import { PropType, defineComponent } from '@vue/composition-api';
import GcDivider from '../components/primitives/GcDivider.vue';
import { StepId } from '../consts/assistant-steps';
import { AssistantContext } from '../config/context';
import steps from '../config/steps';

export default defineComponent({
  components: {
    GcDivider,
  },
  props: {
    currentStep: {
      required: true,
      type: Number,
    },
    showLabels: {
      default: false,
      type: Boolean,
    },
    steps: {
      required: true,
      type: Array as () => Array<string | StepId>,
    },
    context: {
      required: true,
      type: Object as PropType<AssistantContext>,
    }
  },
  setup(props, { emit }) {
    function backToStep(id: string) {
      /**
       * Clear the value of the step being jumped back to
       * and all steps after it
       */
      const stepIds = steps.map((step) => step.id);
      const currentStepIndex = stepIds.indexOf(id);

      for (let i = currentStepIndex; i < steps.length; i++) {
        const currentStepId = stepIds[i];

        if (props.context[currentStepId]?.[currentStepId]) {
          props.context[currentStepId][currentStepId] = []
        }
      }

      emit('transition', {
        event: `BACK_TO_${id.toUpperCase()}`,
      });
    }

    const stepValueSelected = (stepId: string) => {
      return props.context[stepId]?.[stepId]?.length > 0
    }

    return {
      backToStep,
      stepValueSelected,
    };
  },
});
