
































































import { PropType, defineComponent, ref } from '@vue/composition-api';
import SkipMessage from './SkipMessage.vue';
import GcProgressCircular from '../components/primitives/GcProgressCircular.vue';
import AssistantStepsOverview from '../components/AssistantStepsOverview.vue';
import AssistantHeader from '../components/AssistantHeader.vue';
import StartScreen from '../components/StartScreen.vue';
import { StepDefinition } from '../interfaces/step-definition';
import { AssistantMachine } from '../interfaces/assistant';
import { NEXT } from '../consts/assistant-state-events';
import { getTenantConfig } from '../lib/tenants';

export default defineComponent({
  components: {
    AssistantHeader,
    AssistantStepsOverview,
    GcProgressCircular,
    SkipMessage,
    StartScreen,
  },
  props: {
    machine: {
      required: true,
      type: Object as PropType<AssistantMachine>,
    },
    steps: {
      required: true,
      type: Array as PropType<StepDefinition[]>,
    },
  },
  setup(props) {
    const { final, send, currentStepIndex } = props.machine;
    const assistantStarted = ref(false);

    const onTransition = (transition: { event: string; payload?: any }) => {
      send(transition.event, transition.payload);
    };

    const overviewSteps = props.steps.map(step => step?.id);

    const skipStep = () => props.machine.send(NEXT);

    const toggleAssistant = () => assistantStarted.value = !assistantStarted.value;

    return {
      assistantStarted,
      currentStepIndex,
      final,
      onTransition,
      overviewSteps,
      skipStep,
      context: props.machine.context,
      toggleAssistant,
      embeddedInShopware: getTenantConfig().embeddedInShopware,
    };
  },
});
