























import { defineComponent, watch } from '@vue/composition-api';
import AssistantStep from '../../components/AssistantStep.vue';
import GcForm from '../../components/primitives/GcForm.vue';
import ChatQuestion from '../../components/ChatQuestion.vue';
import ChatAnswer from '../../components/ChatAnswer.vue';
import FormField from '../../components/FormField.vue';
import { stepComponentProps } from '../../lib/steps/helper';
import { useBaseTransitions } from '../../lib/xstate/transitions';
import { getStepContext } from '../../lib/context';

export default defineComponent({
  components: {
    AssistantStep,
    ChatAnswer,
    ChatQuestion,
    FormField,
    GcForm,
  },
  props: {
    ...stepComponentProps,
  },
  setup(props, { emit }) {
    const { onNext } = useBaseTransitions(emit);
    const formData = getStepContext(props.assistantContext, props.step!);

    watch(formData.value, value => {
      if ((value as any).customerType.length > 0) {
        onNext();
      }
    });

    return { formData };
  },
});
