import { StepId } from '../../consts/assistant-steps';
import { FormFieldType } from '../../consts/input-types';
import {
  FormMultipleChoiceConfig,
  StepDefinition,
} from '../../interfaces/step-definition';
import component from './Service.vue';

const config: StepDefinition = {
  component,
  fields: [
    {
      config: {
        columns: 3,
        options: [
          {
            icon: 'question',
            value: 'information',
          },
          {
            icon: 'lightbulb-on',
            value: 'solution',
          },
          {
            icon: 'home',
            value: 'modernization',
          },
        ],
        singleAnswer: true,
        verticalOptionLayout: true,
      } as FormMultipleChoiceConfig,
      name: 'service',
      required: true,
      type: FormFieldType.MultipleChoice,
    },
  ],
  id: StepId.SERVICE,
  skippable: true,
};

export default config;
