export enum StepId {
  PRODUCTS = 'products',
  CUSTOMER_TYPE = 'customerType',
  SERVICE = 'service',
  TOPIC = 'topic',
}

export enum StepGroupId {
  PLACEHOLDER = 'placeholder',
}
