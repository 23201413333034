






























import { defineComponent } from '@vue/composition-api';
import GcImage from '../components/primitives/GcImage.vue';
import GcButton from './primitives/GcButton.vue';

export default defineComponent({
  components: {
    GcButton,
    GcImage,
  },
  props: {
    started: {
      default: false,
      type: Boolean,
    },
  },
  setup(_, { emit }) {
    const baseUrl = process.env.VUE_APP_BASE_URL;

    function toggleAssistant() {
      emit('toggle');
    }

    return {
      baseUrl,
      toggleAssistant,
    };
  },
});
