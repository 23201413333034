






























import { defineComponent, onMounted, ref } from '@vue/composition-api';
import ChatQuestion from '../../components/ChatQuestion.vue';
import GcProgressCircular from '../../components/primitives/GcProgressCircular.vue';
import { loadProducts } from '../../lib/data/products';
import { stepComponentProps } from '../../lib/steps/helper';
import stepValueToFilterMap from '../../config/stepValueToFilterMap';
import { StepId } from '../../consts/assistant-steps';
import Product from './Product.vue';

export default defineComponent({
  components: {
    ChatQuestion,
    GcProgressCircular,
    Product,
  },
  props: {
    ...stepComponentProps,
  },
  setup(props) {
    const loading = ref(false);
    const products = ref<string[]>([]);

    /**
     * merge and flatten filter tags asociated with the current context
     */
    const filterTags = [].concat.apply([], [
      ...props.assistantContext.customerType.customerType.map(
        (val: string) => stepValueToFilterMap[StepId.CUSTOMER_TYPE][val] || val
      ),
      ...props.assistantContext.service.service.map(
        (val: string) => stepValueToFilterMap[StepId.SERVICE][val] || val
      ),
      ...props.assistantContext.topic.topic.map(
        (val: string) => stepValueToFilterMap[StepId.TOPIC][val] || val
      ),
    ]);

    onMounted(async () => {
      loading.value = true;
      try {
        products.value = await loadProducts(filterTags);
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    });
    return {
      loading,
      products,
    };
  },
});
