import { StepId } from '../../consts/assistant-steps';
import { FormFieldType } from '../../consts/input-types';
import {
  FormMultipleChoiceConfig,
  StepDefinition,
} from '../../interfaces/step-definition';
import component from './Topic.vue';

const config: StepDefinition = {
  component,
  fields: [
    {
      config: {
        columns: 3,
        options: [
          {
            icon: 'faucet',
            value: 'waterQualityCheck',
            visibilityCondition: context => {
              return context.customerType.customerType.includes('tenant')
                && (context.service.service.includes('information') || context.service.service.includes('solution'));
            },
          },
          {
            icon: 'handshake',
            value: 'consultationPlanningAndFunding',
            visibilityCondition: context => {
              return context.service.service.includes('modernization');
            },
          },
          {
            icon: 'charging-station',
            value: 'electroMobility',
            visibilityCondition: context => {
              return context.customerType.customerType.includes('tenant')
                && (context.service.service.includes('information') || context.service.service.includes('modernization'));
            },
          },
          {
            icon: 'leaf',
            value: 'sustainability',
            visibilityCondition: context => {
              return context.customerType.customerType.includes('tenant')
                && context.service.service.includes('information');
            },
          },
          {
            icon: 'fire-alt',
            value: 'heating',
            visibilityCondition: context => {
              return context.service.service.includes('solution');
            },
          },
          {
            icon: 'user-tie',
            value: 'convinceLandlordToModernize',
            visibilityCondition: context => {
              return context.customerType.customerType.includes('tenant')
                && (context.service.service.includes('solution') || context.service.service.includes('modernization'));
            },
          },
          {
            icon: 'question-circle',
            value: 'consultationPlanningAndFunding',
            visibilityCondition: context => {
              return (context.customerType.customerType.includes('owner') || context.customerType.customerType.includes('propertyManager'))
                && context.service.service.includes('information');
            },
          },
          {
            icon: 'id-card',
            value: 'energyCertificateAndLegalTopics',
            visibilityCondition: context => {
              return (context.customerType.customerType.includes('owner') || context.customerType.customerType.includes('propertyManager'))
                && context.service.service.includes('information');
            },
          },
          {
            icon: 'analytics',
            value: 'buildingChecksAndAnalytics',
            visibilityCondition: context => {
              return (context.customerType.customerType.includes('owner') || context.customerType.customerType.includes('propertyManager'))
                && context.service.service.includes('information');
            },
          },
          {
            icon: 'money-check-alt',
            value: 'grantsAndFunding',
            visibilityCondition: context => {
              return (context.customerType.customerType.includes('owner') || context.customerType.customerType.includes('propertyManager'))
                && context.service.service.includes('modernization');
            },
          },
          {
            icon: 'tools',
            value: 'findCraftsman',
            visibilityCondition: context => {
              return (context.customerType.customerType.includes('owner') || context.customerType.customerType.includes('propertyManager'))
                && (context.service.service.includes('solution') || context.service.service.includes('modernization'));
            },
          },
          {
            icon: 'gavel',
            value: 'legalTopics',
            visibilityCondition: context => {
              return (context.customerType.customerType.includes('owner') || context.customerType.customerType.includes('propertyManager'))
                && context.service.service.includes('solution');
            },
          },
        ],
        singleAnswer: true,
        verticalOptionLayout: true,
      } as FormMultipleChoiceConfig,
      name: 'topic',
      required: true,
      type: FormFieldType.MultipleChoice,
    },
  ],
  id: StepId.TOPIC,
  skippable: true,
};

export default config;
