import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAnalytics,
  faAngleLeft,
  faAngleRight,
  faBuilding,
  faCalendarAlt,
  faCalendarWeek,
  faChargingStation,
  faCheck,
  faClock,
  faCompass,
  faEdit,
  faFaucet,
  faFireAlt,
  faGavel,
  faHandshake,
  faHome,
  faIdCard,
  faKey,
  faLeaf,
  faLightbulbOn,
  faMinus,
  faMoneyCheckAlt,
  faPencilAlt,
  faPlus,
  faQuestion,
  faQuestionCircle,
  faSearch,
  faTimes,
  faTools,
  faUser,
  faUserTie,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faFileAlt,
  faFileImage,
  faFilePdf,
  faFilePlus,
  faIceCream,
  faUpload,
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faSquare,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
  faAnalytics,
  faAngleLeft,
  faAngleRight,
  faCheck,
  faEdit,
  faMinus,
  faPencilAlt,
  faPlus,
  faSearch,
  faTimes,
  faQuestion,
  faCalendarAlt,
  faCalendarWeek,
  faClock,
  faIceCream,
  faUpload,
  faFilePlus,
  faFileAlt,
  faFilePdf,
  faFileImage,
  faSquare,
  faUser,
  faBuilding,
  faHome,
  faTools,
  faCompass,
  faKey,
  faLightbulbOn,
  faHandshake,
  faMoneyCheckAlt,
  faChargingStation,
  faFaucet,
  faFireAlt,
  faGavel,
  faIdCard,
  faLeaf,
  faQuestionCircle,
  faUserTie,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
